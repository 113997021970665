<template>
	<div class="app-container">
		<formItem
			class="queryForm"
			style="margin: 10px 0"
			ref="formItem"
			:formItems="formItems"
			@cancel="$router.go(-1)"
			:defaultData="formModel"
			:showFooter="false"
		>
		</formItem>
		<div class="views_wrap">
			<empty v-if="!tableRealData.length" />
			<div v-for="(item, inx) in tableRealData" :key="item.id" :class="`view_item ${isFullScreen && fullScreenInx === inx && 'fullscreen'}`">
				<div @click="() => handleTitle(inx)">
					<el-row class="titleRow">
						<el-col :span="2"><i :class="activeNames.includes(inx) ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"></i></el-col>
						<el-col :span="8" v-for="ite in titleList" :key="ite.key">
							<div class="titleText">
								<span>{{ ite.label }}: </span>
								<span :title="ite.formatter ? ite.formatter(item[ite.key]) : item[ite.key] || ''">{{
									ite.formatter ? ite.formatter(item[ite.key]) : item[ite.key] || ''
								}}</span>
							</div>
						</el-col>
						<el-col :span="6" style="text-align: right; padding-right: 10px" v-show="!isDownLoading">
							<el-button
								size="mini"
								type="primary"
								icon="el-icon-edit"
								@click.stop="() => handleEditAnalysis(item)"
								v-if="permissionControlBtns(pageName, 'Edit')"
							>
								编辑
							</el-button>
							<el-button
								size="mini"
								type="danger"
								icon="el-icon-delete"
								@click.stop="(e) => handleDel(e, item)"
								v-if="permissionControlBtns(pageName, 'Delete')"
							>
								删除
							</el-button>
						</el-col>
					</el-row>
				</div>
				<el-collapse-transition>
					<div class="table_wrap" v-show="activeNames.includes(inx)">
						<div class="table_item" :style="isFullScreen && `height: 100vh`">
							<baseTable :columns="columns" :showPagination="false" :dataList="item.tableData || []" />
						</div>
					</div>
				</el-collapse-transition>
			</div>
		</div>
		<!-- 添加产品线 -->
		<el-dialog :title="dialogTitle" top="30vh" center :visible.sync="dialogVisible" :close-on-click-modal="false" :close-on-press-escape="false">
			<formItem
				ref="formItem"
				class="formItem"
				:formItems="dialogItems"
				@submit="this.addAnalysisSubmit"
				@cancel="dialogVisible = false"
				:defaultData="dialogModel"
				style="margin: 0 50px"
			>
			</formItem>
		</el-dialog>
		<!-- 编辑节点  -->
		<el-dialog
			:title="dialogTitle"
			top="30vh"
			width="600px"
			center
			:visible.sync="addNodeVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
		>
			<formItem
				ref="formItem"
				class="formItem"
				:formItems="dialogNodeItems"
				@submit="this.addAnalysisSubmit"
				@cancel="addNodeVisible = false"
				:defaultData="dialogFormModel"
			>
			</formItem>
		</el-dialog>
		<!-- 导入按钮的弹窗 -->
		<!-- <Upload :visible.sync="dialogImportVisible" :config="uploadConfig" /> -->
	</div>
</template>

<script>
import formItem from '@/views/components/components-form-item/index.vue';
import baseTable from '@/views/components/components-base-table';
import empty from '@/views/components/components-empty/index.vue';
// import Upload from '@/components/Upload/index.vue';
// import { debounce } from 'loadsh';
import { Loading } from 'element-ui';
// import moment from 'moment';
// import { printDiv } from '@/utils/util';
export default {
	name: 'marketInsightindustry',
	components: { formItem, baseTable, empty },

	data() {
		return {
			visible: true,
			dialogVisible: false,
			dialogModel: {},
			dialogItems: [
				{
					label: '产品线',
					key: 'productId',
					type: 'select',
					class: 'pruductClass',
					defaultFirstOption: true,
					options: () => {
						const { setData } = this.$store.state;

						return setData.options.productId || [];
					},
					optEdit: true,
					footerRender: true,
					optProps: { add: '/v1/common/productConfig/save', del: '/v1/common/productConfig/delete', key: 'name' },
					rules: [{ required: true, message: '请选择', trigger: 'change' }],
					span: 24
				},
				{
					label: '战略目标',
					key: 'strategicIntentId',
					type: 'select',
					async: () =>
						this.$axios.post('/v1/doRightThing/strategicIntent/getList', { request: {} }).then((res) =>
							res.map(({ overallEndTime, overallStartTime, id, strategicGoals, productId }) => ({
								dictId: id,
								dictName: `${overallStartTime}财年-${overallEndTime}财年`,
								strategicGoals,
								productId
							}))
						),
					filterOpts: (opts, { productId }) => {
						if (productId) {
							return opts?.filter((e) => e.productId === productId) || [];
						}
						return opts;
					},
					rules: [{ required: true, message: '请选择', trigger: 'change' }],
					change: (formData, e) => {
						if (formData?.strategicIntentId) {
							formData.strategicGoals = e?.strategicGoals;
						} else {
							formData.strategicGoals = '';
						}
					},
					span: 10
				},
				{
					label: '-',
					labelWidth: '30px',
					key: 'strategicGoals',
					type: 'textarea',
					readOnly: true,
					span: 14
				}
			],
			formItems: [
				{
					label: '时间',
					key: 'annual',
					type: 'year',
					span: 6,
					labelWidth: '60px'
				},
				{
					label: '产品线',
					key: 'productId',
					type: 'select',
					async: () =>
						this.$axios
							.get('/v1/common/productConfig/getList', { request: {} })
							.then((res) => res.map(({ id, name }) => ({ dictId: id, dictName: name }))),
					span: 6
				},
				{
					span: 3,
					labelWidth: '0px',
					style: 'min-width: 100px',
					render: () => (
						<el-button size="small" type="primary" style="margin-top: 5px" icon="el-icon-search" onclick={this.queryData}>
							查询
						</el-button>
					)
				},
				{
					span: 9,
					labelWidth: '0px',
					style: 'text-align: right;min-width: 310px',
					render: () => (
						<div style="min-width: 310px">
							{this.permissionControlBtns(this.pageName, 'Add') && (
								<el-button
									size="small"
									type="primary"
									style="margin-top: 5px"
									icon="el-icon-plus"
									onclick={() => {
										this.dialogModel = {};
										this.dynamicTags = [];
										this.dynamicCustTags = [];
										this.dialogTitle = '添加产品线';
										this.dialogType = 'add';
										this.dialogVisible = true;
									}}
								>
									添加产品线
								</el-button>
							)}
						</div>
					)
				}
			].filter((v) => v),
			formModel: { annual: new Date().getFullYear().toString() },
			columns: [
				{
					label: '模块',
					key: 'model'
				},
				{
					label: '设计的关键要点',
					key: 'gist',
					render: this.getRenderByKey('gist'),
					showOverflow: false,
					align: 'left'
				}
			],
			dataList: [],
			titleList: [
				// {
				// 	label: '时间',
				// 	key: 'createTime',
				// 	formatter: (val) => moment(val).format('YYYY-MM-DD')
				// },
				{ label: '产品线', key: 'productName' },
				{ label: '战略目标', key: 'strategicGoals' }
			],
			showMenuTips: false,
			menuStyle: {},
			addNodeVisible: false,
			addTagVisible: false,
			dialogFormModel: {},
			dialogTitle: '新增',
			dialogType: '',
			treeChartList: [],
			// debounceResize: debounce(this.resize, 300),
			activeNames: [0],
			isDownLoading: false,
			dialogImportVisible: false,
			// 导入配置
			// uploadConfig: {
			// 	dictId: 'importTargetInfo',
			// 	groupId: 'importTemplate',
			// 	importUrl: '/v1/performance/target/importTargetInfo',
			// 	successMsg: '年度目标差距分析导入成功',
			// 	callBack: () => {
			// 		this.queryData();
			// 		this.dialogImportVisible = false;
			// 	}
			// },
			inputVisible: false,
			inputTagValue: '',
			selectColor: '',
			isFullScreen: false,
			fullScreenInx: '',
			dynamicTags: [],
			addInputVal: '',
			tableRealData: [],
			editNodeData: {},
			showOperation: [],
			dynamicCustTags: []
		};
	},
	watch: {},
	computed: {
		pageName() {
			return this.$route.name;
		},
		dialogNodeItems() {
			const { targetLabel, targetKey } = this.editNodeData || {};

			let arr = [
				{
					label: targetLabel || '',
					key: targetKey,
					type: 'select',
					groupId: 'innovationStrategy',
					formatOpts: ({ dictId }) => ({ dictId, dictName: dictId }),
					span: 24,
					rules: { required: true, message: '请输入', trigger: 'blur' },
					change: (formData, e) => {
						formData.strategyCharacteristics = e?.dictName;
					}
				},
				{
					label: '特点',
					key: 'strategyCharacteristics',
					type: 'input',
					disabled: true
				}
			];

			if (this.dialogType === 'editGist') {
				arr = [
					{
						label: targetLabel || '',
						labelWidth: '140px',
						key: targetKey,
						type: 'textarea',
						span: 24,
						rules: { required: true, message: '请输入', trigger: 'blur' }
					}
				];
			}
			return arr;
		}
	},
	async mounted() {
		// 查询数据
		this.queryData();
	},

	methods: {
		// 查询数据视图
		queryData() {
			const loading = Loading.service({ target: '.views_wrap' });

			this.$axios
				.post('/v1/doRightThing/businessDesign/getList', { request: { ...this.formModel } })
				.then((res) => {
					loading.close();
					if (res.code) {
						return;
					}
					this.$delete(res, '_responseStatusCode');
					this.tableRealData = res.map((val) => ({ ...val, tableData: val?.modelGists?.map((e) => ({ ...e, tableId: val.tableId })) }));
				})
				.catch(() => {
					loading.close();
				});
		},
		// 添加 分析提交
		addAnalysisSubmit(form) {
			form.validate((valid) => {
				if (valid) {
					let params = { ...this.dialogModel, type: '1' };

					if (['editGist'].includes(this.dialogType)) {
						params = { modelGist: { ...this.dialogFormModel }, type: '2' };
					}
					const loading = Loading.service({ target: '.formItem' });

					this.$axios
						.post('/v1/doRightThing/businessDesign/save', {
							request: params
						})
						.then((res) => {
							loading.close();
							if (res.code) {
								return;
							}
							this.dialogVisible = false;
							this.addNodeVisible = false;
							this.$message.success('保存成功');
							this.dialogModel = {};
							this.dialogFormModel = {};
							this.queryData();
						})
						.catch(() => {
							this.dialogVisible = false;
							this.addNodeVisible = false;
						});
				}
			});
		},
		// 嵌套数据铺平
		unfoldListCol(cols, childKey = 'child') {
			if (cols?.length === 0 || !cols) {
				return [];
			}
			let arr = [];

			cols.forEach((item) => {
				const p = item[childKey]?.length ? [...this.unfoldListCol(item[childKey])] : item;

				arr = arr.concat(p);
			});
			return arr;
		},
		// 删除节点
		handleDel(e, item) {
			this.showMenuTips = false;
			this.$confirm('是否确定删除?', '删除提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$axios
					.post('/v1/doRightThing/businessDesign/delete', {
						request: { id: item.id }
					})
					.then((res) => {
						if (res && res.code === 0) {
							this.$message.success('删除成功!');

							this.queryData();
						}
					});
			});
		},
		// 编辑节点
		handleEdit(row) {
			// this.dialogType = 'editNode';
			this.dialogType = 'editGist';
			this.showMenuTips = false;
			this.addNodeVisible = true;
			this.dialogTitle = '编辑';
			this.dialogFormModel = { ...row };
		},
		// 编辑 分析
		handleEditAnalysis(data) {
			this.dialogType = 'editAnalysis';
			const params = {
				...data
			};

			this.dialogModel = params;
			this.dialogTitle = '编辑';
			this.dialogVisible = true;
		},
		// 隐藏展示
		handleTitle(i) {
			if (this.isFullScreen) {
				return;
			}
			if (this.activeNames.includes(i)) {
				this.activeNames = this.activeNames.filter((val) => val !== i);
			} else {
				this.activeNames.push(i);
			}
		},
		mouseEnter(row, inx, key) {
			const cols = this.unfoldListCol(this.columns, 'children');
			const colInx = cols.map(({ key }) => key).indexOf(key);

			const { label, key: targetKey } = cols[colInx];

			this.editNodeData = { ...row, targetLabel: label, targetKey };
			this.showOperation = [inx, key, row.tableId];
		},
		// 表格 自定义
		getRenderByKey(key) {
			return (row, inx) => {
				const showOper = this.showOperation[0] === inx && this.showOperation[1] === key && this.showOperation[2] === row.tableId;

				const showNotEdit = !['strategyCharacteristics'].includes(this.editNodeData.targetKey);

				return (
					<div class="mergeDiv" onMouseenter={() => this.mouseEnter(row, inx, key)} onMouseleave={() => (this.showOperation = [])}>
						<el-row style="width:100%">
							<el-col span={20}>{row[key] || ''}</el-col>
							{this.permissionControlBtns(this.pageName, 'EditCell') && showOper && (
								<el-col span={4}>
									{
										<div style="cursor:pointer;color:#409EFF">
											{showNotEdit && (
												<i
													class="el-icon-edit"
													style="margin-right: 4px"
													onClick={() => this.handleEdit(row, key)}
													title="编辑"
												></i>
											)}
										</div>
									}
								</el-col>
							)}
						</el-row>
					</div>
				);
			};
		}
	},
	beforeDestroy() {}
};
</script>

<style lang="scss" scoped>
.app-container {
	// padding: 30px 20px !impo rtant;
	.table_item {
		// height: 600px;
		width: 100%;
		// margin: 20px 0;
	}
	.titleRow {
		cursor: pointer;
		// height: 40px;
		// line-height: 40px;
		// border-bottom: 1px solid #dcdcdc;
		padding: 10px;
		display: inline-flex;
		width: 100%;
		align-items: center;
		.titleText {
			font-size: 14px;
			color: rgba(0, 0, 0, 0.4);
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.is-round {
			padding: 7px;
		}
	}

	.views_wrap {
		background: #fff;

		.table_wrap {
			position: relative;
			overflow: hidden;
			border-top: 1.8px solid #dcdcdc;
			.menuTips {
				position: fixed;
			}
		}
		.view_item {
			// box-shadow: 0px 0px 2px #999;
			// margin: 2px;
			margin-bottom: 20px;
			border: 1.8px solid #dcdcdc;
		}
		.fullscreen {
			position: fixed;
			width: 100vw;
			height: 100vh;
			top: 0;
			left: 0;
			z-index: 1001;
			background: #fff;
		}
	}
	.el-tag {
		margin-right: 10px;
		margin-bottom: 10px;
	}
	.button-new-tag {
		margin-left: 10px;
		height: 32px;
		line-height: 30px;
		padding-top: 0;
		padding-bottom: 0;
	}
	.tag-title {
		height: 30px;
		line-height: 30px;
	}
	.color-item {
		height: 30px;
		ul {
			display: inline-flex;
			width: 100%;
			height: 100%;
			li {
				margin-right: 20px;
				margin-bottom: 10px;
				width: 20px;
				border-radius: 50%;
				cursor: pointer;
				opacity: 0.6;
			}
		}
	}
	.tag-item {
		cursor: pointer;
		border: none;
	}
	.input-new-tag {
		width: 90px;
		margin-left: 10px;
		vertical-align: bottom;
		margin-right: 10px;
		margin-bottom: 10px;
	}
}
</style>
<style lang="scss">
.queryForm {
	.formStyle {
		margin-bottom: 12px;
	}
	.el-form-item {
		margin-bottom: 10px;
	}
}
.table_wrap {
	.table_item {
		.el-table__row .el-table__cell .cell {
			height: 100%;
			.mergeDiv {
				display: flex;
				justify-content: space-between;
				min-height: 20px;
				height: 100%;
				align-items: center;
			}
		}
	}
}
</style>
